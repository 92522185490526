<script setup>

import {defineProps} from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
});

</script>

<template>
  <div v-if="props.loading" class="loader-container">
    <img src="https://images-development-codi.s3.amazonaws.com/postobon-frisby/points-CTA.webp" alt="">


    <div class="loader">
      <div class="loader-point"></div>
      <div class="loader-point"></div>
      <div class="loader-point"></div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.loader-container {
  height: 100vh;
  width: 100%;
  background: url("https://images-development-codi.s3.us-east-1.amazonaws.com/postobon-frisby/OCR-Postobon/fondo-loader.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 999;
  img{
    width: 20%;
  }
}
.loader{
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
}

.loader-point{
  width: 1.5rem;
  height: 1.5rem;
  margin: 3rem .5rem;
  border-radius: 50%;
  background-color: #ffffff;
  animation: loader .6s infinite alternate;
}

@keyframes loader {
  to{
    opacity: .1;
    transform: translate3d(0, -1rem, 0);
  }
}

.loader-point:nth-child(2){
  animation-delay: .2s;
}

.loader-point:nth-child(3){
  animation-delay: .4s;
}
</style>
